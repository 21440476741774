<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid" v-if="!isLoading">

      <div class="row mt-3" v-if="user && user.can_search">
        <!-- <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_companies' }"
            @click="$router.push({ name: 'investors_companies' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-moneybag larger"></i>
              </div>
              <h6 class="text-uppercase mt-0" v-if="siteCompany.is_whitelabel">Your Investors</h6>
              <h6 class="text-uppercase mt-0" v-else>Investors</h6>
              <h3 class="mt-2 mb-0">
                <span v-if="companiesLoading">
                  <loader size="28px"></loader>
                </span>
                <span v-else-if="companiesLoaded && companies">{{ $filters.money(companies.count | 0) }}</span>
                <span v-else>--</span>
              </h3>
            </div>
          </div>
        </div> -->
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_contacts' }"
            @click="$router.push({ name: 'investors_contacts' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-user larger"></i>
              </div>
              <div>
                <h6 class="text-uppercase mt-0" v-if="siteCompany.is_whitelabel">Your Contacts</h6>
                <h6 class="text-uppercase mt-0" v-else>Contacts</h6>
                <h3 class="mt-2 mb-0">
                  <span v-if="contactsLoading">
                    <loader size="28px"></loader>
                  </span>
                  <span v-else-if="contactsLoaded && contacts">{{ $filters.money(contacts.count | 0) }}</span>
                  <span v-else>--</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col" v-if="siteCompany.is_whitelabel">
          <div
              class="card widget-flat cursor-pointer hover-opacity"
              :class="{ 'bg-secondary text-white': $route.name === 'astel_campaigns' }"
              @click="$router.push({ name: 'astel_campaigns' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-monitor larger"></i>
              </div>
              <div>
                <h6 class="text-uppercase mt-0">Astel Network</h6>
                <h3 class="mt-2 mb-0">
                  <span v-if="astelContactsLoading">
                    <loader size="28px"></loader>
                  </span>
                  <span v-else-if="astelContactsLoaded && astelContacts">{{ $filters.money(astelContacts.count | 0) }}</span>
                  <span v-else>--</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_searches' }"
            @click="$router.push({ name: 'investors_searches' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-bookmark larger"></i>
              </div>
              <h6 class="text-uppercase mt-0">Saved List</h6>
              <h3 class="mt-2 mb-0 text-overflow">
                <span v-if="search">{{ $filters.truncate(search.saved_search_name || '', 15) }}</span>
                <span v-else>--</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_personalise' }"
            @click="$router.push({ name: 'investors_personalise' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-comment-check larger"></i>
              </div>
              <h6 class="text-uppercase mt-0">Personalise</h6>
              <h3 class="mt-2 mb-0" v-if="search">
                <span v-if="personaliseContacts && personaliseContacts.count">{{ $filters.money(personaliseContacts.count) }}</span>
                <span v-else>
                  --
                </span>
                <!-- <span class="small text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 2.13%</span> -->
              </h3>
              <h3 class="mt-2 mb-0" v-else>
                --
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_outreach' }"
            @click="$router.push({ name: 'investors_outreach' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-message larger"></i>
              </div>
              <h6 class="text-uppercase mt-0">Connect</h6>
              <h3 class="mt-2 mb-0" v-if="search">
                <span v-if="outreach && outreach.count">{{ $filters.money(outreach.count) }}</span>
                <span v-else>
                  --
                </span>
                <!-- <span class="small text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 2.13%</span> -->
              </h3>
              <h3 class="mt-2 mb-0" v-else>
                --
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name.startsWith('pitch_deck') }"
            @click="$router.push({ name: 'pitch_deck_upload' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-chart larger"></i>
              </div>
              <h6 class="text-uppercase mt-0">Pitch Deck Analysis</h6>
              <h3 class="mt-2 mb-0">
                &nbsp;
              </h3>
            </div>
          </div>
        </div>
      </div>

      <template v-if="routeAllowed">
        <!-- Begin page -->
        <router-view></router-view>
        <!-- end wrapper-->
      </template>

      <hr class="space space-md" />
      <app-footer></app-footer>
    </div>
    <div v-else class="pt-4">
      <loader class="d-flex justify-content-center"></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { update } from '@intercom/messenger-js-sdk'

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'dashboard',

  components: {
    'app-header': Header,
    'app-footer': Footer,
  },

  data() {
    return {
      setupDone: false,
      isSubmitting: false,
      isLoading: true,
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      user: state => state.auth.user,
      siteCompany: state => state.auth.siteCompany,
      startup: state => state.startups.current,
      startups: state => state.startups.startups,
      search: state => state.investors.currentSearch,
      companies: state => state.investors.companies,
      companiesLoading: state => state.investors.companiesLoading,
      companiesLoaded: state => state.investors.companiesLoaded,
      searches: state => state.investors.searches,
      contacts: state => state.investors.contacts,
      astelContacts: state => state.astelInvestors.contacts,
      contactsLoading: state => state.investors.contactsLoading,
      astelContactsLoading: state => state.astelInvestors.contactsLoading,
      contactsLoaded: state => state.investors.contactsLoaded,
      astelContactsLoaded: state => state.astelInvestors.contactsLoaded,
      personaliseContacts: state => state.personalise.contacts,
      outreach: state => state.outreach.contacts,
    }),
    routeAllowed() {
      // checks if the product is ready for whitelabel clients
      if (
        this.$route.path.startsWith('/me') ||
        this.$route.path.startsWith('/startup') ||
        this.$route.path.startsWith('/contacts')
      ) {
        return true
      }

      if (
        this.siteCompany && !this.siteCompany.is_whitelabel
      ) {
        return true
      }

      if (
        this.startup
      ) {
        return true
      }

      return false
    }
  },
  
  created() {
    this.$store.commit('SET_COMPANIES_LOADING', false)
    this.$store.commit('SET_CONTACTS_LOADING', false)
  },

  mounted() {
    if (this.user) {
      update({
        name: this.user.first_name + ' ' + this.user.last_name,
        email: this.user.email,
        created_at: this.user.date_joined,
        user_id: this.user.id,
        company: this.siteCompany ? {
          company_id: this.siteCompany.id,
          name: this.siteCompany.name
        } : null
      });
    }

    if (!this.startups) {
      this.$store.dispatch('LOAD_STARTUPS')
        .then((response) => {
          const startups = response.data.results
          if (!this.startup) {
            if (this.user && !this.user.startup && startups && startups.length) {
              this.$router.push({ name: 'startups_selector' })
            } else if (this.user && this.user.startup) {
              this.$router.push(`/startups/${this.user.startup}`)
            } else {
              this.$router.push({ name: 'startup_profile_add' })
            }
            return
          }          
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    if (this.startup) {
      this.$store.dispatch('RELOAD_CURRENT_STARTUP')
    }

    // load search options
    Promise.all([
      this.$store.dispatch('LOAD_USER'),
      this.$store.dispatch('LOAD_SEARCH_FIELDS'),
      this.$store.dispatch('LOAD_SEARCH_OPTIONS'),
      this.$store.dispatch('LOAD_STARTUPS')
    ]).finally(() => {
      this.isLoading = false
    })

    // this.loadStartupContent()
  },

  methods: {
    loadStartupContent() {
      console.log(this.startup)
      return Promise.all([
        this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId: this.startup.id }),
        this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id }),
      ])
    },

    goGenerateMatchScore() {
      if (confirm('Are you sure you want to trigger generation of results for this startup?')) {
        this.isSubmitting = true
        this.$store.dispatch('GENERATE_MATCH_SCORE', { startupId: this.startup.id })
          .then(() => {
            this.$store.dispatch('RELOAD_CURRENT_STARTUP')
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
    },
  },

  watch: {
    'startup': {
      handler: function (val, oldVal) {
        if (val && (!oldVal || oldVal.id !== val.id)) {
          this.loadStartupContent()
        }
      }
    }
  }
}
</script>
